import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainHeaderComponent } from './common-components/main-header/main-header.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { HttpClientModule } from '@angular/common/http';
import { LogoutService } from './shared/services/logout';
import { CargarFacturaService } from './shared/services/cargar-factura.service';
import { FilterHistorialClientesService } from './main/services/filter-historial-clientes.service';

@NgModule({
  declarations: [
    AppComponent,
    MainHeaderComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LogoutService,
    CargarFacturaService,
    FilterHistorialClientesService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
