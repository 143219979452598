import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from 'src/app/shared/services/session';

@Injectable()
export class LogoutService {

  constructor(private session:Session,
    private router:Router) { }

  logout(){
    this.session.clear();
    this.router.navigate(['/']);
  }
}
