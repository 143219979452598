import { Component } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { Session } from './shared/services/session';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public spinkit = Spinkit;

  constructor(public session:Session) {

  }
}
