import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from 'src/app/shared/services/session';
import { ToastService } from './toast.service';

@Injectable()
export class CargarFacturaService {

  constructor(private toastService:ToastService) { }

  init(){
    window.localStorage.removeItem('FACT');
    window.localStorage.setItem('FACT',JSON.stringify([]));
  }

  getItems(){
    if (window.localStorage.getItem('FACT')) {
      return JSON.parse(window.localStorage.getItem('FACT')).sort().reverse();
    } else {
      return [];
    }
  }
  
  getItem(invoice){
    let array = JSON.parse(window.localStorage.getItem('FACT'));
    let find = array.find(element => element.invoice == invoice);
    return find;
  }

  newItem(item,edit?){
    let items = this.getItems();
    let find = items.find(element=> element.invoice == item.invoice);

    if(find)
    {
      this.toastService.presentToast('Ya posee una factura cargada con este número',5000);
      return;
    } 
    else{
      items.push(item);
      edit ? '' : this.toastService.presentToast('Factura cargada en su listado de facturas',5000);
    } 
    try {
      window.localStorage.setItem('FACT',JSON.stringify(items));
    } catch(exception) {
      this.toastService.presentToast('Has llegado al número máximo de facturas que puedes cargar simultaneamente, '
      + 'por favor baja la resolución de las imagenes seleccionadas o bien '
      + 'confirma las que tienes en tu carretilla y sube el siguiente lote.',10000);
    }
  }

  removeItem(invoice,edit?){
    let items = this.getItems();
    let find = items.findIndex(element=> element.invoice == invoice);
    items.splice(find,1);
    edit ? '' : this.toastService.presentToast('Factura eliminada',5000);
    window.localStorage.setItem('FACT',JSON.stringify(items));
  }

  removeItems(){
    window.localStorage.setItem('FACT',JSON.stringify([]));
  }

  edit(item,itemDelete){
    let items = this.getItems();
    let find = items.find(element=> element.invoice == item.invoice);
    let findIndex = items.findIndex(element=> element.invoice == itemDelete);

    /*
    if(find)
    {
      //this.toastService.presentToast('Ya posee una factura cargada con este número',5000);
      //return;
    } 

    else{*/
      items.splice(findIndex,1);
      items.push(item);
      this.toastService.presentToast('Factura editada correctamente',2000);
    //} 
    
    window.localStorage.setItem('FACT',JSON.stringify(items));
  }

  clear(){
    window.localStorage.removeItem('FACT');
  }
}