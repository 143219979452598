import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ListStates } from 'src/app/main/interfaces/list-departamentos';
import { ListMunicipios } from 'src/app/main/interfaces/list-municipios';

@Injectable({
  providedIn: 'root'
})
export class Session {

  private tokenKey:string = 'TK';
  private citiesKey:string = 'CITIES';
  private statesKey:string = 'STATES';
  private client:string = 'CLIENT';
  private distribuidor:string = 'DISTRIBUIDOR';

  constructor() { }

  clear(){
    window.localStorage.clear();
    window.localStorage.clear();
  }

  setToken(token){
    window.localStorage.removeItem(this.tokenKey);
    window.localStorage.setItem(this.tokenKey,token);
  }

  getToken(){
    return window.localStorage.getItem(this.tokenKey);
  }

  setStates(states){
    window.localStorage.removeItem(this.statesKey);
    window.localStorage.setItem(this.statesKey,JSON.stringify(states));
  }

  getStates():ListStates{
    return JSON.parse(window.localStorage.getItem(this.statesKey));
  }

  setCities(cities){
    window.localStorage.removeItem(this.citiesKey);
    window.localStorage.setItem(this.citiesKey,JSON.stringify(cities));
  }

  getCities():ListMunicipios{
    return JSON.parse(window.localStorage.getItem(this.citiesKey));
  }

  setClient(client){
    window.localStorage.removeItem(this.client);
    window.localStorage.setItem(this.client,JSON.stringify(client));
  }

  getClient(){
    return JSON.parse(window.localStorage.getItem(this.client));
  }

  setDistribuidor(distribuidor){
    window.localStorage.removeItem(this.distribuidor);
    window.localStorage.setItem(this.distribuidor,JSON.stringify(distribuidor));
  }

  getDistribuidor(){
    return JSON.parse(window.localStorage.getItem(this.distribuidor));
  }

}
