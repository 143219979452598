import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Session } from 'src/app/shared/services/session';
import { environment } from 'src/environments/environment';
import { ListStates } from '../interfaces/list-departamentos';
import { ListHistorialClientes } from '../interfaces/list-historial-clientes';
import { ListMunicipios } from '../interfaces/list-municipios';
import { ResultListFacturasCliente } from '../interfaces/result-list-facturas-cliente';
import { ResultListHistorialClientes } from '../interfaces/result-list-historial-clientes';

@Injectable()
export class FilterHistorialClientesService {

  constructor(private http:HttpClient,
    private session:Session) { }

  getStates():ListStates{
    return this.session.getStates();
  }

  getMunicipios():ListMunicipios{
    return this.session.getCities();
  }

  getHistorialClientes(command):Observable<ResultListHistorialClientes>{
    let body = {
      clientSearch:{
        city:command.municipio || '',
        name:command.texto || '',
        nit:command.nit || '',
        state:command.departamento,
        zone:command.zone || ''
      },
      token:this.session.getToken()
    }
    return this.http.post(environment.baseUrl + environment.client_list,body)
      .pipe(map((data:ResultListHistorialClientes) => data));
  }

  getFacturas(staff_id):Observable<ResultListFacturasCliente>{
    let body = {
      staff_id:staff_id,
      token:this.session.getToken()
    };
    return this.http.post(environment.baseUrl + environment.facturas_list,body)
      .pipe(map((data:ResultListFacturasCliente) => data));
  }

  getDistribuidores(command):Observable<any>{
    let body = {
      distributorSearch:{
        city:command.municipio || '',
        name:command.texto || '',
        nit:command.nit || '',
        state:command.departamento,
        zone:command.zone || ''
      },
      token:this.session.getToken()
    };
    return this.http.post(environment.baseUrl + environment.distributor_list,body)
  }

  saveAll(items):Observable<any>{
    let body = {
      data:items,
      token:this.session.getToken()
    };
    return this.http.post(environment.baseUrl + environment.saveAll,body)
  }

}
