import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { DetailFacturasComponent } from 'src/app/main/components/detail-facturas/detail-facturas.component';
import { CargarFacturaService } from 'src/app/shared/services/cargar-factura.service';
import { LogoutService } from 'src/app/shared/services/logout';
import { Session } from 'src/app/shared/services/session';

import {Location} from '@angular/common';
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {

  public distribuidor:String;
  public cliente:String;

  constructor(private menu: MenuController,
    private router:Router,
    private logoutService:LogoutService,
    public cargarFacturaService:CargarFacturaService,
    private modalController:ModalController,
    public session:Session,
    private _location: Location) { }

  ngOnInit() {
    this.cliente = this.session.getClient() ? this.session.getClient().business_name : ""
    this.distribuidor = this.session.getDistribuidor() ? this.session.getDistribuidor().name : ""
  }

  open(){
    this.menu.isOpen('first').then( l => l ? this.menu.close('first') : this.menu.open('first'));
  }

  navigateTo(route){
    this.router.navigate([route]);
    this.menu.close();
  }

  logout(){
    this.logoutService.logout();
    this.navigateTo('/');
  }

  detailFacturas() {

    /*const modal = await this.modalController.create({
      component: DetailFacturasComponent,
      cssClass: 'my-custom-class',
      swipeToClose:false,
      keyboardClose:true
    })

    return await modal.present();*/
    
    this.router.navigate(['/main/detail-facturas']);
  }

  ingresarFactura() {    
    this.router.navigate(['/main/cargar-factura',{edit: false }]);
  }

}
